import Modal from 'react-modal'
import { updateCountryModal } from '@/redux/actions/uiAction'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { getRegion } from '@/lib/region-helper'
import { trans } from '@/lib/locale-helper'

const CountryModal = (props) => {
  const dispatch = useDispatch()
  const { countryModal } = useSelector((state) => state.uiReducer)
  let renderData = props.countryModal.currentCountry.countryData[0]

  const setLanguage = (item) => {
    let nextCountry = getRegion(item.prefix)
    let countryUrl = ''

    let alternateLink = document.querySelector(`[hreflang="${nextCountry.hreflang}"]`)

    if (alternateLink) {
      let href = alternateLink.href;

      countryUrl = `${href}`;
    } else {
      let domain = nextCountry.domain == 'en-us' ? 'us/en-us' : nextCountry.domain

      countryUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/${domain}`
    }

    localStorage.removeItem('user_checkout_locale');

    window.location = countryUrl;
  }

  const stayOnCountry = async () => {
    localStorage.setItem('navigatedomain', 0)
    await dispatch(updateCountryModal(false))
  }

  return (
    <>
      <Modal
        closeTimeoutMS={300}
        isOpen={countryModal}
        className="modal-login"
        overlayClassName="modal__overlay"
        onRequestClose={stayOnCountry}
      >
        <div className="login-modal relative lg:py-10 lg:px-10 md:px-30 md:py-10 2xs:px-5 2xs:py-30">
          <button
            type="button"
            className="outline-none focus:outline-none absolute country-modal-close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={async () => await dispatch(updateCountryModal(false))}
              className="absolute md:top-14.25 md:right-14.25 2xs:top-9.25 2xs:right-9.25"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.469684 0.46967C0.762577 0.176777 1.23745 0.176777 1.53034 0.46967L8.00001 6.93934L14.4697 0.46967C14.7626 0.176777 15.2374 0.176777 15.5303 0.46967C15.8232 0.762563 15.8232 1.23744 15.5303 1.53033L9.06067 8L15.5304 14.4697C15.8232 14.7626 15.8232 15.2374 15.5304 15.5303C15.2375 15.8232 14.7626 15.8232 14.4697 15.5303L8.00001 9.06066L1.53033 15.5303C1.23744 15.8232 0.762563 15.8232 0.46967 15.5303C0.176777 15.2374 0.176777 14.7626 0.46967 14.4697L6.93935 8L0.469684 1.53033C0.176791 1.23744 0.176791 0.762563 0.469684 0.46967Z"
                fill="black"
              />
            </svg>
          </button>
          <div
            className="country-selector__fit-wrap relative h-full"
            style={{ border: 'solid 1px rgba(196, 196, 196, 0.3)' }}
          >
            <div className="relative mx-auto w-full flex items-center h-full z-30">
              <div>
                <h4 className="country-selector__fit-title md:text-smd md:leading-18 2xs:leading-15 2xs:text-spgs font-primary text-black">
                  {trans('you-are-on', 'You’re on the')} {props.countryModal.visitedCountry.name}{' '}
                  {trans('but-it-looks-like', 'site, but it looks like you’re in')}{' '}
                  {renderData.name}.<br />
                  <div className="mt-3">
                    {trans('what-would-you-do', 'What would you like to do?')}
                  </div>
                </h4>
                <div className="lg:mt-44 md:mt-33 2xs:mt-42">
                  <span className={`flag-icon ${props.countryModal.visitedCountry.flag}`}></span>
                  <span
                    onClick={stayOnCountry}
                    className="ml-2 border-b border-gray-400 lg:text-smd lg:leading-18 2xs:text-spgs 2xs:leading-14 cursor-pointer hover:text-gray-600 active:text-gray-400"
                  >
                    {trans('stay-on', 'STAY ON')}{' '}
                    {props.countryModal.visitedCountry.name.toUpperCase()} {trans('site', 'SITE')}{' '}
                    {props.countryModal.visitedCountry.sign}
                  </span>
                </div>
                <div className="lg:mt-60r lg:mb-0 md:mb-30 2xs:mb-18 md:mt-46 2xs:mt-46 country-lower">
                  {props.countryModal.currentCountry.countryData.length > 1 ? (
                    <>
                      <span className={`flag-icon ${renderData.flag}`}></span>
                      <span className="ml-2 lg:text-smd lg:leading-18 2xs:text-spgs 2xs:leading-14 cursor-pointer">
                        {trans('go-to', 'GO TO')} {renderData.name.toUpperCase()}{' '}
                        {trans('site', 'SITE')} {renderData.sign}
                      </span>
                      <div className="block ml-7">
                        {props.countryModal.currentCountry.countryData.map((item, key) => {
                          return (
                            <a
                              key={key}
                              onClick={() => setLanguage(item)}
                              className="inline-block underline uppercase cursor-pointer lg:text-smd lg:leading-18 2xs:text-spgs 2xs:leading-14 hover:text-gray-600 active:text-gray-400"
                            >
                              {item.text}
                            </a>
                          )
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <span className={`flag-icon ${renderData.flag}`}></span>
                      <span
                        onClick={() => setLanguage(renderData)}
                        className="ml-2 lg:text-smd lg:leading-18 2xs:text-spgs 2xs:leading-14 cursor-pointer hover:text-gray-600 active:text-gray-400"
                      >
                        {trans('go-to', 'GO TO')} {renderData.name.toUpperCase()}{' '}
                        {trans('site', 'SITE')} {renderData.sign}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <svg
              className="w-full h-full absolute inset-0 stroke-current text-gray-400"
              style={{ opacity: '0.3' }}
            >
              <line x1="0" y1="100%" x2="100%" y2="0" strokeWidth="1" />
              <line x1="100%" y1="100%" x2="0" y2="0" strokeWidth="1" />
            </svg>
          </div>
        </div>
      </Modal>
      <style jsx>
        {`
          .login-modal {
            max-width: 545px;
          }

          .country-selector__fit-wrap > div {
            border-left: solid 1px rgba(196, 196, 196, 0.3);
            border-right: solid 1px rgba(196, 196, 196, 0.3);
          }

          .country-selector__fit-wrap > div::before {
            border-left: solid 1px rgba(196, 196, 196, 0.3);
            border-right: solid 1px rgba(196, 196, 196, 0.3);
            content: '';
            display: block;
            height: 100%;
            left: 30%;
            position: absolute;
            top: 0;
            width: 10px;
          }

          .country-selector__fit-wrap > div::after {
            border-right: solid 1px rgba(196, 196, 196, 0.3);
            border-left: solid 1px rgba(196, 196, 196, 0.3);
            content: '';
            display: block;
            height: 100%;
            right: 30%;
            position: absolute;
            top: 0;
            width: 10px;
          }

          .country-modal-close {
            top: 10px;
            right: 28.25px;
          }
          .country-selector__fit-title {
            margin-top: -2px;
          }

          .country-lower {
            margin-bottom: -4px;
          }
        `}
      </style>
    </>
  )
}

export default CountryModal